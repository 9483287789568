<div class="modal-header">
  <h5 class="modal-title">Custom Fields</h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <form #loanPassCustomFieldSectionsForm="ngForm" novalidate id="loanPassCustomFieldSectionsForm">
    <loading-indicator *ngIf="loadingCustomFieldSections" [loadingMessage]="'Loading Custom Field Sections...'"
      [customClass]="'no-shadow'"></loading-indicator>
    <ng-container *ngIf="!loadingCustomFieldSections">
      <div class="row mb-4">
        <div class="col-md-12">
          <button class="btn btn-outline-primary" (click)="addSection()">
            Add Section
          </button>
        </div>
      </div>
      <div cdkDropList
           [cdkDropListData]="sections"
           class="list"
           (cdkDropListDropped)="drop($event)"
           *ngIf="sections && sections.length > 0">
        <div *ngFor="let section of sections; let index = index;" [cdkDragData]="section" cdkDrag>
          <span class="close" (click)="deleteSection(section); $event.stopPropagation()">
            <i id="close-icon" class="fas fa-times-circle"></i>
          </span>
          <div class="card">
            <div class="card-header d-flex">
              <div class="col-6 d-flex">
                <span class="p-element pi pi-bars p-datatable-reorderablerow-handle me-2 align-self-center"></span>
                <text-input [(ngModel)]="section.name" name="sectionName_{{section['tempUniqueId']}}" class="w-100"
                [editorMode]="editorMode" [placeholder]="'Section Name'" (ngModelChange)="sectionNameChanged()" [required]="isRequiredSectionName"></text-input>
              </div>
              <div class="col-6 text-end">
                <button class="btn btn-outline-primary" (click)="addCustomField(section)">
                  Add Custom Field
                </button>
              </div>
            </div>
            <div class="card-body" (mousedown)="$event.stopPropagation()">
              <pricing-custom-fields-v2 [customFields]="section.customFields" [dependsOnCustomFields]="dependsOnCustomFields" (customFieldNameChanged)="onCustomFieldNameChanged()"></pricing-custom-fields-v2>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">
    Cancel
  </button>
  <button type="button" class="btn btn-primary right" (click)="onSaveClicked()">
    Save
  </button>
</div>
