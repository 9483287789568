<div class="notification-menu p-1" data-simplebar="init">
  <div class="row">
      <div class="col-md-4">
          <conversation-list
              [conversations]="allConversations"
              (showArchiveChanged)="onShowArchiveChanged($event)"
              (conversationSelected)="onConversationSelected($event)"
              (impersonatedUserChanged)="onImpersonatedUserChanged($event)"
              [borrowerId]="borrowerId">
          </conversation-list>
      </div>
      <div class="col-md-8">
          <conversation-details *ngIf="phoneNumbersAvailableToTextFrom"
            [userId]="userId"
            [phoneNumbersAvailableToTextFrom]="phoneNumbersAvailableToTextFrom"
            [conversation]="selectedConversation"
            [globalConfig]="globalConfig"
            (editBorrower)="onEditBorrowerClicked($event)"
            (editLead)="onEditLeadClicked($event)"
            (onArchiveConversation)="archiveConversation($event)"
            (onConversationUpdated)="onConversationUpdated($event)"
            (smsConversationCreated)="onSmsConversationCreated($event)"
            (conversationGroupCreated)="onConversationGroupCreated($event)">
          </conversation-details>
      </div>
  </div>
</div>
