import { Component, ElementRef, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { ConversationService } from '../../services/conversation.service';
import { ChatBorrower, ChatLead } from '../../models/chat-related-entities.model';
import { GlobalConfig } from 'src/app/models/config/global-config.model';
import { lastValueFrom, Observer, Subscription } from 'rxjs';
import { DrawerUtils } from '../../../../shared/utils/drawer-utils';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { NotificationService } from 'src/app/services/notification.service';
import { ConversationTargetPersonContext } from 'src/app/layout/admin/admin.component';
import { Conversation } from '../../models/conversation.model';
import * as _ from 'lodash';
import { VoiceSmsSettingsService } from 'src/app/modules/voice-sms-settings/services/voice-sms-settings.service';
import { TelephonyService } from 'src/app/modules/voice-sms-settings/modules/telephony-service.model';

@Component({
  selector: 'conversations-panel',
  templateUrl: 'conversations-panel.component.html',
  styleUrls: ['./conversations-panel.component.scss'],
})

export class ConversationsPanelComponent extends ApplicationContextBoundComponent implements OnInit, OnDestroy {

  @Input()
  allConversations: Conversation[];

  @Input()
  globalConfig: GlobalConfig;

  @Input()
  userId: string;

  @Input()
  conversationTargetPersonContext: ConversationTargetPersonContext;

  @Input()
  borrowerId: number | undefined;

  @Output()
  editBorrower: EventEmitter<ChatBorrower> = new EventEmitter<ChatBorrower>();

  @Output()
  editLead: EventEmitter<ChatLead> = new EventEmitter<ChatLead>();

  @Output()
  impersonatedUserChanged: EventEmitter<string> = new EventEmitter<string>();

  protected selectedConversation: Conversation;

  protected showArchive: boolean = false;

  protected isLoaded: boolean = false;

  protected phoneNumbersAvailableToTextFrom: TelephonyService[] = null;

  private _contextSubscription: Subscription;

  constructor(
    private readonly _elRef: ElementRef,
    private readonly injector: Injector,
    private readonly _conversationService: ConversationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifsService: NotificationService,
    private readonly _voiceSmsSettingsService: VoiceSmsSettingsService) {
    super(injector);
  }

  async ngOnInit() {
    await lastValueFrom(DrawerUtils.drawerIsReady$());

    const drawer = DrawerUtils.getOpenedDrawer();
    const drawerBody = drawer?.querySelector(':scope > *') as HTMLElement | undefined;
    if (drawerBody == null) {
      throw Error('Cannot find the drawer body');
    }

    DrawerUtils.removeEmptyChildren(drawerBody);

    const excludedHeight = DrawerUtils.calculateHeaderAndFooterHeight(drawerBody);
    (this._elRef.nativeElement as HTMLElement).style.setProperty('height', `calc(100% - ${excludedHeight}px)`);

    this._contextSubscription = this.applicationContextService.context.subscribe(context => {
      this.getAvailablePhoneNumbersThatUserCanTextWith(context.currentlyLoggedInUser.userId);
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._contextSubscription?.unsubscribe();
  }

  private getAvailablePhoneNumbersThatUserCanTextWith = (userId: string) => {
    const observer: Observer<TelephonyService[]> = {
      next: (result: TelephonyService[]) => {
        this.phoneNumbersAvailableToTextFrom = result.filter(t => t.userId === userId || !t.userId && t.fromPhoneNumber);
      },
      error: (err: any) => {
        
      },
      complete: () => {
      }
    };
    this._spinner.show();
    this._voiceSmsSettingsService.getTelephonyList().subscribe(observer)
    .add(() => {
      this._spinner.hide();
    });
  }

  onSmsConversationCreated = (conversation: Conversation) => {

  }

  onConversationSelected = (conversation: Conversation) => {
    if (!conversation) {
      return;
    }
    const isInitalLoad = !!conversation && !this.selectedConversation;
    if (isInitalLoad) {
      if (this.conversationTargetPersonContext) {
        conversation.applicationId = this.conversationTargetPersonContext.applicationId;
        conversation.agentId = this.conversationTargetPersonContext.agentId;
        conversation.borrowerId = this.conversationTargetPersonContext.borrowerId;
        conversation.leadId = this.conversationTargetPersonContext.leadId;
      }
    }
    if (this.selectedConversation) {
      this.selectedConversation.isActive = false;
    }
    conversation.isActive = true;
    this.selectedConversation = conversation;
  }

  onConversationUpdated = (conversation: Conversation) => {
    const index = this.allConversations.findIndex(x => x.conversationId === conversation.conversationId);
    if (index !== -1) {
      this.allConversations[index] = _.clone(conversation);
      this.allConversations = [...this.allConversations];
      this.onConversationSelected(conversation);
    } else {
      console.log("Conversation not found");
    }
  }

  onConversationGroupCreated = (conversation: Conversation) => {
    this.allConversations.unshift(conversation);
    this.allConversations = [...this.allConversations];
    this.onConversationSelected(conversation);
  }

  archiveConversation = (archive: boolean) => {
    let archiveStatus = archive ? 'archive' : 'unarchive'
    let text = "Are you sure you want to " + archiveStatus + " this conversation?";
    let title = archiveStatus[0].toUpperCase() + archiveStatus.substr(1) + ' Conversation'
    Swal.fire({
      title: title,
      text: text,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result: SweetAlertResult) => {
      if (!result.value) {
        return;
      }
      this._spinner.show();
      this._conversationService.changeHiddenStatus(this.selectedConversation.conversationId, archive).subscribe(result => {
        this._spinner.hide();
        const index = this.allConversations.findIndex(c => c.conversationId == this.selectedConversation.conversationId);
        this.allConversations[index].isHidden = archive;
        if (!this.showArchive) {
          this.allConversations[index].isActive = false;
          this.allConversations[index + 1].isActive = true;
        }
        this.allConversations = [... this.allConversations];
      }, (err) => {
        this._spinner.hide();
        this._notifsService.showError(err?.message || "Couldn't change archive status", "Error!");
      });
    });
  }

  onShowArchiveChanged = (showArchive: boolean) => {
    this.showArchive = showArchive;
  }

  onImpersonatedUserChanged = (userId: string) => {
    this.selectedConversation = null;
    this.impersonatedUserChanged.emit(userId);
  }

  onEditBorrowerClicked = (borrower: ChatBorrower) => {
    this.editBorrower.emit(borrower);
  }

  onEditLeadClicked = (lead: ChatLead) => {
    this.editLead.emit(lead);
  }
}
