import { Injectable } from '@angular/core';
import { forkJoin, Observable, Subject } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { DUProvider } from 'src/app/models/aus/du-providers.model';
import { VoaAssetReadonlyArray } from 'src/app/models/voa/voa-asset.model';
import { FreddieMacUnderwriteRequest } from 'src/app/modules/loan-services/aus/models/freddie-mac-underwrite-request.model';
import {
  AUSBroker,
  AusDuAgenciesArray,
  AusRequest,
  CreateAccountCheckReportApiResponse,
  CreditReport,
  DisclosureHistory,
  DisclosureLoanPlanArray,
  DisclosureRequestData,
  DuHistory,
  DuHistoryArray,
  ExtendedMortgageBorrower,
  FormFreeHistory,
  FormFreeReportHistory,
  LiteAccountInfoReadonlyArray,
  LpaHistory,
  MortgageDocumentSet,
  TwnHistoriesArray,
  TwnHistory
} from '../../models';
import { TpoConfiguration } from 'src/app/modules/admin/tpo-config/models/tpo-configuration.model';
import { BorrowerVerificationUserEnrollmentRequest } from 'src/app/models/voa/borrower-verification-user-enrollment-request';
import { BorrowerVerificationUserEnrollmentResponse } from 'src/app/models/voa/borrower-verification-user-enrollment-response';
import { CreditTechnicalAffiliate } from 'src/app/models/credit/credit-technical-affiliate.model';
import { UpdateBorrowerVerificationSessionRequest } from 'src/app/models/voa/update-borrower-verification-session-request';
import { GenerateBorrowerVerificationReportRequest } from 'src/app/models/voa/generate-borrower-verification-report-request';
import { GenerateBorrowerVerificationReportResponse } from 'src/app/models/voa/generate-borrower-verification-report-response';

@Injectable()
export class LoanServicesService {

  private _forceEditSubject: Subject<boolean> = new Subject<boolean>();
  private _editBrokerLenderSubject: Subject<boolean> = new Subject<boolean>();

  get forceEditChanges(): Subject<boolean> {
    return this._forceEditSubject;
  }

  get editBrokerLenderChanges(): Subject<boolean> {
    return this._editBrokerLenderSubject;
  }

  constructor(private readonly _dataService: DataService) { }

  //#region twn loan service
  public getTwnHistories = (
    loanId: number,
    isExtendedDataExcluded?: boolean
  ): Observable<TwnHistoriesArray> => {
    let url = `api/twn/history?applicationId=${loanId}`;
    if (isExtendedDataExcluded) {
      url += '&excludeExtendedData=true';
    }
    return this._dataService.get(url);
  };

  public getTwnHistoryDoc = (twnHistoryId: number): Observable<any> => {
    return this._dataService.get(`api/integration-history/${twnHistoryId}/document`, {
      headers: { 'Accept': 'application/json, text/plain, */*' },
      responseType: "arraybuffer"
    });
  };

  public runVoi = (
    expandedBorrowersForms: Array<ExtendedMortgageBorrower>
  ): Observable<TwnHistoriesArray> => {
    const promises: Array<Promise<TwnHistory>> = [];
    expandedBorrowersForms.forEach((formData) => {
      const promise = this._dataService
        .post(`api/twn/verification`, formData)
        .toPromise();
      promises.push(promise);
    });
    return forkJoin(promises);
  };
  //#endregion

  //#region aus
  public getDuAgencies = (showAll: boolean, credentialId?: number): Observable<AusDuAgenciesArray> => {
    return this._dataService.get(
      `api/fannie-mae/credit/agencies?showAll=${showAll}${credentialId ? `&thirdPartyCredentialId=${credentialId}` : ''}`
    );
  };

  public getDOAgencies = (showAll: boolean, credentialId?: number): Observable<AusDuAgenciesArray> => {
    let url = `api/fannie-mae/do/credit/agencies?showAll=${showAll}${credentialId ? `&thirdPartyCredentialId=${credentialId}` : ''}`;
    return this._dataService.get(url);
  };

  public getDOLenders = (credentialId?: number): Observable<Array<AUSBroker>> => {
    let url = "api/fannie-mae/do/lenders";
    if (credentialId) {
      url += `?thirdPartyCredentialId=${credentialId}`
    }
    return this._dataService.get(url);
  };

  public releaseCasefile = (thirdPartyOrderId: string): Observable<void> => {
    return this._dataService.post(`api/fannie-mae/do/order/${thirdPartyOrderId}/release`, null);
  };

  public linkCasefile = (appId: number, thirdPartyOrderId: string): Observable<void> => {
    return this._dataService.post(`api/fannie-mae/order/${thirdPartyOrderId}/link?applicationId=${appId}`, null);
  };

  public finalizeDO = (request: AusRequest): Observable<DuHistory> => {
    return this._dataService.post('api/fannie-mae/do/underwrite', request);
  }

  public getLatestDOOrder = (applicationId: number, updateStatusFromDO: boolean = false): Observable<any> => {
    return this._dataService.get(`api/fannie-mae/do/order?applicationId=${applicationId}&updateFromThirdParty=${updateStatusFromDO}`);
  }

  public getDUProviders = (userId: string, companyId: number, branchId: number): Observable<DUProvider> => {
    return this._dataService.get(
      `api/third-party-credential/creditProviders?userId=${userId}&companyId=${companyId}&branchId=${branchId}`
    );
  };

  public getDOProviders = (userId: string, companyId: number, branchId: number): Observable<DUProvider> => {
    return this._dataService.get(
      `api/third-party-credential/creditProviders?userId=${userId}&companyId=${companyId}&branchId=${branchId}&integrationProvider=FannieMae&integrationService=AUSOriginator`
    );
  };

  public getDuHistory = (
    loanId: number
  ): Observable<DuHistoryArray> => {
    const url = `api/fannie-mae/history?`;
    let query = "";

    if (loanId) {
      query += `applicationId=${loanId}`;
    }

    return this._dataService.get(url + query);
  };

  public getLpaHistory = (loanId: number): Observable<LpaHistory[]> => {
    return this._dataService.get(
      `api/freddie-mac/history?applicationId=${loanId}`
    );
  };

  public getLastRanLpa = (loanId: number): Observable<LpaHistory> => {
    return this._dataService.get(
      `api/freddie-mac/history/last?applicationId=${loanId}`
    );
  };

  public getLpaDefaultParams = (loanId: number): Observable<FreddieMacUnderwriteRequest> => {
    return this._dataService.get(
      `api/freddie-mac/underwrite/default-request?applicationId=${loanId}`
    );
  };

  public runDuProcess = (data: any): Observable<DuHistory> => {
    return this._dataService.post(`api/fannie-mae/underwrite`, data);
  };

  public runLpaProcess = (data: any): Observable<any> => {
    return this._dataService.post(`api/freddie-mac/underwrite`, data);
  };

  public getTechnicalAffiliates = (creditVendor: string): Observable<CreditTechnicalAffiliate[]> => {
    return this._dataService.get(
      `api/freddie-mac/technical-affiliates-vendor?creditVendor=${creditVendor}`
    );
  };

  public downloadDocumentOldFormat = (
    integrationHistoryDocumentId: string
  ): Observable<any> => {
    return this._dataService.get(
      `api/integration-history/document/${integrationHistoryDocumentId}`,
      { responseType: 'arraybuffer' }
    );
  };

  public downloadDualFormat = (
    isDu: boolean,
    integrationHistoryDocumentId: string
  ): Observable<any> => {
    const url = isDu
      ? `api/fannie-mae/history/documents/${integrationHistoryDocumentId}/contents`
      : `api/freddie-mac/history/documents/${integrationHistoryDocumentId}/contents`;
    return this._dataService.get(url, { responseType: 'arraybuffer' });
  };


  forceEditChange = (value: boolean) => {
    this._forceEditSubject.next(value);
  }

  editBrokerLenderChange = (value: boolean) => {
    this._editBrokerLenderSubject.next(value);
  }
  //#endregion

  public getCreditReportHistoryForApplication = (
    loanId: number
  ): Observable<CreditReport[]> => {
    return this._dataService.get(
      `api/creditInfo/history?applicationId=${loanId}`
    );
  };

  public getCreditReportHistoryForLead = (
    leadId: number
  ): Observable<CreditReport[]> => {
    return this._dataService.get(`api/creditInfo/history?leadId=${leadId}`);
  };

  public getTpoConfiguration = (): Observable<TpoConfiguration> => {
    return this._dataService.get(`api/Admin/tpo-configuration`);
  };

  public getExtensionByMimeType = (mimeType: string) => {
    let extension = '';

    switch (mimeType) {
      case 'text/plain':
        extension = '.txt';
        break;
      case 'application/xml':
        extension = '.xml';
        break;
      case 'application/pdf':
        extension = '.pdf';
        break;
      case 'text/html':
        extension = '.html';
        break;
      default:
        extension = '';
    }
    return extension;
  };

  //#region digital assets verification loan service (form-free)
  public getFormFreeHistory = (
    loanId: number
  ): Observable<FormFreeHistory[]> => {
    return this._dataService.get(`api/form-free/history?loanId=${loanId}`);
  };

  public getFormFreeReportHistory = (
    loanId: number
  ): Observable<FormFreeReportHistory[]> => {
    return this._dataService.get(`api/form-free/report?loanId=${loanId}`);
  };

  public getAccountLiteInfo = (
    transactionId: string
  ): Observable<LiteAccountInfoReadonlyArray> => {
    return this._dataService.get(`api/form-free/${transactionId}/lite`);
  };

  public getLatestReport = (transactionId: string): Observable<any> => {
    return this._dataService.get(
      `api/form-free/${transactionId}/report/latest`
    );
  };

  public getAssets = (
    accountChekOrderId: string,
    reportId: string,
    applicationId: number
  ): Observable<VoaAssetReadonlyArray> => {
    return this._dataService.get(
      `api/form-free/${accountChekOrderId}/assets/${reportId}?applicationId=${applicationId}`
    );
  };

  public importAssets = (
    accountChekOrderId: string,
    reportId: string,
    applicationId: number,
    data: Object
  ): Observable<VoaAssetReadonlyArray> => {
    return this._dataService.post(
      `api/form-free/${accountChekOrderId}/assets/${reportId}/import?applicationId=${applicationId}`,
      data
    );
  };

  public importEmployments = (borrowerVerificationOrderId : number): Observable<any> => {
    return this._dataService.post(
      `api/form-free/orders/${borrowerVerificationOrderId}/employments/import`,
      {}
    );
  };

  public upgradeOrder = (borrowerVerificationOrderId: number,req: UpdateBorrowerVerificationSessionRequest): Observable<FormFreeHistory> => {
    return this._dataService.post(`api/form-free/orders/${borrowerVerificationOrderId}`, req);
  };

  public processVerificationOrderEvents = (borrowerVerificationOrderId: number): Observable<any> => {
    return this._dataService.post(`api/form-free/orders/${borrowerVerificationOrderId}/process-events`, {});
  };

  public generatePdfReport = (
    borrowerVerificationOrderId : number,
    req: GenerateBorrowerVerificationReportRequest
  ): Observable<GenerateBorrowerVerificationReportResponse> => {
    return this._dataService.post(`api/form-free/orders/${borrowerVerificationOrderId}/report`, req);
  };

  public repullPdfReport = (
    accountChekOrderId: string,
    reportId: string
  ): Observable<string> => {
    return this._dataService.get(
      `api/form-free/${accountChekOrderId}/report/${reportId}`
    );
  };

  public inviteBorrowerToVoaVoi = (request: BorrowerVerificationUserEnrollmentRequest): Observable<BorrowerVerificationUserEnrollmentResponse> => {
    let url = `api/form-free/invite`
    return this._dataService.post(url,request);
  };

  public copyTransferLink = (transactionId: string): Observable<string> => {
    return this._dataService.post(
      `api/form-free/${transactionId}/ownership/link`,
      {},
      null,
      {
        responseType: "text",
      }
    );
  };

  public getFormFreeRequestTypeOptions = (): Observable<any> => {
    return this._dataService.get(`api/form-free/request-type-options`);
  };

  public getFormFreeAccountMonitoringOptions = (): Observable<any> => {
    return this._dataService.get(`api/form-free/account-monitoring-options`);
  };

  public getFormFreeReportUpgradeOptions = (): Observable<any> => {
    return this._dataService.get(`api/form-free/report-upgrade-options`);
  };

  //#endregion

  //#region disclosures

  public getDisclosureHistory = (loanId: number, isRecent: boolean = false): Observable<Array<DisclosureHistory>> => {
    return this._dataService.get(`api/disclosure/${loanId}/history?mostRecentOnly=${isRecent}`);
  };

  public getDisclosureHistoryById = (historyId: number): Observable<DisclosureHistory> => {
    return this._dataService.get(`api/disclosure/history/${historyId}`);
  };

  public getDisclosureLoanPlans = (): Observable<DisclosureLoanPlanArray> => {
    return this._dataService.get(`api/disclosure/loan-plans`);
  };

  public requestDisclosure = (data: DisclosureRequestData): Observable<{ item1: DisclosureHistory }> => {
    return this._dataService.post(`api/disclosure`, data);
  };

  public getDisclosureDocumentSets = (loanId: number): Observable<MortgageDocumentSet[]> => {
    return this._dataService.get(`api/disclosure/${loanId}/document-sets`);
  };

  //#endregion
}
