export class ConversationResponse {
  count: number;
  records: Conversation[];
}

export class Conversation {
  conversationId: number;
  assignedTo: string;
  chatMedium: ChatMedium;
  externalName: string;
  externalState: string;
  lastReadDate: Date;
  mostRecentMessageId: number;
  mostRecentIncomingMessageDate?: Date;
  mostRecentOutgoingMessageDate?: Date;
  mostRecentMessageDate?: Date;
  isUnread: boolean;
  mostRecentMessagePreview: string;
  companyId: number;
  applicationId?: number;
  leadId?: number;
  borrowerId?: number;
  agentId?: number;
  insertedBy: string;
  isHidden: boolean;
  updatedBy: string;
  dateInserted: Date;
  dateUpdated: Date;
  participants: ConversationParticipant[] = [];

  assignedUserId: string;
  assignedUserDate: Date;
  twilioConversationSid: string;
  modelGuid: string;

  //client side only stuff
  conversationMessages: ConversationMessage[] = [];
  isActive: boolean = false;
  constructor() {
    this.conversationMessages = [];
    this.participants = [];
  }
  contactAvatarColor: string;
  contactAlias: string = "N/A";

  // This is when the same external participant(s) have been talked to using different phone numbers of the user
  // The user talked to the same participant(s) using different phone numbers - again client side only
  conversations: Conversation[] = [];
}

// This is a strictly client side model, used by the UI
export class ConversationGroup {
  phoneNumberUserUsedForChat: string;
  conversations: Conversation[] = [];
}

export class ConversationParticipant {
  id?: number;
  conversationId?: number;
  name: string;
  phoneNumber: string;
  participantType: ParticipantType;
  companyId?: number;
  dateInserted?: Date;
  dateUpdated?: Date;
  twilioParticipantSid?: string;
  modelGuid: string;
}

export class ConversationMessage {
  id: number;
  from?: string;
  to?: string;
  body: string;
  direction: MessageDirection;
  smsMessageSid?: string;
  messageSid: string;
  accountSid?: string;
  messageModelId: 0;
  hasMediaFiles: boolean;
  messageMediaFileUrls?: string[] = [];
  companyId: 0;
  insertedBy: string;
  updatedBy: string;
  dateInserted: Date;
  dateUpdated: Date;
  groupDate: string;
  mediaFiles: any[];
  mentionTrackingGuid?: string;
  mentionTrackingUrl?: string;

  senderName?: string;
  senderNumber?: string;
}

export enum ParticipantType {
  Internal = "Internal",
  External = "External"
}

export enum MessageDirection {
  Unspecified = "Unspecified",
  Outbound = "Outbound",
  Inbound = "Inbound"
}


export enum ChatMedium {
  BorrowerMessage = "BorrowerMessage",
  InternalMessage = "InternalMessage",
  AgentMessage = "AgentMessage",
  DirectMessage = "DirectMessage",
  Sms = "Sms",
  GroupMessage = "GroupMessage"
}

