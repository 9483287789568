<div class="alert alert-light fade show border-0 mb-0" *ngIf="isShowVendorsPanel && vendorSelection" role="alert"
  id="alert-window" style="position: relative !important;" [ngStyle]="{'top': isTpoUser ? '-23px' : '-7px'}">
  <div class="media">
    <i style="color:rgb(46, 168, 226)" class="fas fa-diagnoses alert-icon align-self-center font-30 me-3"></i>
    <div class="media-body align-self-center">
      <span class="mb-1 fw-bold mt-0">You are currently pricing with vendor: '{{vendorSelection}}'</span><br>
      <span> <a (click)="changeVendorsClicked()" style="color:rgb(46, 168, 226)">Click Here To
          Change Vendors</a></span>
    </div>
  </div>
</div>

<ng-container *ngIf="viewType == 1">
  <div class="row">
    <div class="col-md-12 g-0 accordion mb-1" *ngIf="showScenarios && viewType != 3"
      [ngStyle]="{'margin-top': isTpoUser ? '-18px' : ''}">
      <div class="accordion-item">
        <h5 class="accordion-header m-0" id="headingOne">
          <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"
            (click)="onScenariosAccordionHeaderClicked()">
            Scenarios ({{pricingScenarios.length}})
          </button>
        </h5>

        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne">
          <div class="accordion-body">
            <pinned-scenarios #pinnedScenarios [ngClass]="{'d-flex': pricingScenarios.length > 0}"
              *ngIf="isScenariosSectionOpen" [scenarios]="pricingScenarios"
              (scenarioDeleted)="onScenarioDeleted($event)" (refreshAllFinished)="onRefreshAllScenariosFinished()">
            </pinned-scenarios>
          </div>
        </div>
      </div>

      <div class="accordion-item" *ngIf="!canPriceApplyToLoan && isTpoUser">
        <h5 class="accordion-header m-0" id="headingThree">
          <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse"
            [ngClass]="{ 'collapsed': isQuickPriceHistoryCollapsed }" data-bs-target="#collapseThree"
            aria-expanded="true" aria-controls="collapseThree" (click)="onQuickPriceHistoryAccordionClicked()">
            Quick Pricer History
          </button>
        </h5>

        <div id="collapseThree" class="accordion-collapse collapse"
          [ngClass]="{ 'show': !isQuickPriceHistoryCollapsed }" aria-labelledby="headingThree">
          <div class="accordion-body">
            <quick-price-history (runAgainClicked)="onRunQuickPriceSearchFromHistoryItemClicked($event)"
              #quickPricerHistory>
            </quick-price-history>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="row d-flex" [ngClass]="isShowVendorsPanel ? 'min-height-setter-with-vendor-panel' : 'min-height-setter'">
    <div
      [ngClass]="parameterPanelFullScreen ? 'col-12': 'col-md-3 col-sm-12 col-lg-3 col-xlg-3 d-sm-block restricted-width'"
      class="card border-radius-reset pe-0 g-0 accordion mb-xs-1">
      <h5 class="accordion-header d-sm-none m-0" id="headingTwo">
        <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse"
          data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
          Search products
        </button>
      </h5>
      <div id="collapseTwo" class="accordion-collapse collapse show h-100" aria-labelledby="headingTwo">
        <div class="d-flex flex-column" style="height: 100%">
          <div class="overflow-setter flex-grow-1"
            [ngStyle]="{'padding-bottom': isShowVendorsPanel ? '36px' : '10px', 'height': getPricingParameterHeight()}">
            <loading-indicator *ngIf="!productSearchRequestInfo" [loadingMessage]="'Loading scenario, please wait...'">
            </loading-indicator>
            <i class="expand-btn" [ngClass]="parameterPanelFullScreen ? 'ti-arrow-left': 'ti-arrow-right'"
              (click)="parameterPanelFullScreen = !parameterPanelFullScreen"></i>
            <pricing-parameters #pricingParameters
              *ngIf="productSearchRequestInfo && allEnabledBusinessChannelPerVendor" 
              [transactionType]="transactionType"
              [channelSelectionEnabled]="enableChannelSelection"
              [businessChannelId]="businessChannelId" 
              [canPriceApplyToLoan]="canPriceApplyToLoan"
              [enabledChannels]="enabledChannels" 
              [visibleFeatureFields]="visibleFeatureFields" 
              [productSearchRequest]="productSearchRequestInfo.request"
              [allEnabledBusinessChannelPerVendor]="allEnabledBusinessChannelPerVendor"
              [fieldSpecs]="fieldSpecs" 
              [customFields]="customFields"
              [customFieldSections]="pricingCustomFieldSections"
              [disableCreditScore]="false" 
              [disableDti]="productSearchRequestInfo.disableDTI"
              [applicationChannel]="currentApplication?.channel" 
              [showMultipleColumns]="parameterPanelFullScreen"
              (businessChannelChanged)="onBusinessChannelChanged($event)"
              (vendorChanged)="onVendorChanged($event)">
            </pricing-parameters>
            <loading-indicator [customClass]="'no-shadow'" *ngIf="!productSearchRequestInfo || !allEnabledBusinessChannelPerVendor"></loading-indicator>
          </div>
          <div class="text-center p-3 button-container"
            [ngStyle]="{'bottom': isShowVendorsPanel && currentApplication?.applicationId ? '40px' : '-14px'}">
            <button *ngIf="productSearchRequestInfo" type="button"
            [ngClass]="{'mb-2': !(isShowVendorsPanel && currentApplication?.applicationId)}"
              class="btn btn-primary submit-button-style-child max-index" id="searchProducts"
              (click)="onSubmitClicked()">
              <i class="fas fa-search me-1"></i> Search Products
            </button>
          </div>
        </div>
      </div>
    </div>


    <div *ngIf="!parameterPanelFullScreen" class="col-md-9 col-sm-12 col-lg-9 col-xlg-9 col-12 flex-grow-1 pe-0 ps-xs-0"
      style="height: 100%">
      <div class="card rounded-2 search-result-container" *ngIf="!pricingRunResult">
        <div class="card-body message-container">
          <h3 *ngIf="!isSubmitted" class="rounded-3">Please click 'Search Products' to see the product/pricing info.
          </h3>
          <h3 *ngIf="isSearching" class="rounded-3">Searching for Products...</h3>
        </div>
      </div>
      <!-- <loading-indicator *ngIf="!pricingRunResult && isSubmitted" [loadingMessage]="'Loading pricing results, please wait...'"></loading-indicator> -->
      <product-search-results #searchResults *ngIf="pricingRunResult"
        [canPriceApplyToLoan]="canPriceApplyToLoan"
        [hideQmColumn]="hideQmColumn"
        [miIntegrationEnabled]="miIntegrationEnabled"
        [lockStatus]="lockStatus"
        [transactionType]="transactionType"
        [pricedScenarios]="pricedScenarios"
        [pricingRunResult]="pricingRunResult"
        [pricingRunRequest]="pricingRunRequest"
        [existingPinnedPricesByScenario]="existingPinnedPricesByScenario"
        (ineligibleProductDetailsClicked)="onIneligibleProductDetailsClicked($event)"
        [requestLockEnabled]="requestLockEnabled"
        (productDetailsClicked)="onEligibleProductDetailsClicked($event)"
        (qualifiedMortgageDetailsClicked)="onQualifiedMortgageDetailsClicked($event)"
        (productPriceSelectedForApplication)="onProductPriceSelectedForApplication($event)"
        (productPriceRepricedForApplication)="onProductPriceRepricedForApplication($event)"
        (productPriceProgramChangeForApplication)="onProductPriceProgramChangeForApplication($event)"
        (pricePinned)="onPricePinned($event)"
        (priceUnpinned)="onPriceUnpinned($event)"
        (addToScenariosRequested)="onAddToScenariosRequested($event)"
        (createToScenarioRequested)="onCreateToScenariosRequested($event)"
        (showLoanQuoteRequested)="onShowLoanQuoteClickedForPinnedPrice($event)"
        (requestLockClicked)="onRequestLockClicked($event)">
      </product-search-results>
    </div>
  </div>
</ng-container>

<div class="row" *ngIf="viewType == 2">
  <ul class="nav nav-tabs" role="tablist">
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{ active: tab === 'pricingParameters' }" (click)="selectTab('pricingParameters')"
        data-bs-toggle="tab" href="#pricingParameters" role="tab" aria-selected="true"> Pricing Parameters </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{ active: tab === 'pricingResults' }" (click)="selectTab('pricingResults')"
        data-bs-toggle="tab" href="#pricingResults" role="tab" aria-selected="false"> Pricing Results </a>
    </li>
    <li class="nav-item" *ngIf="showScenarios && pricingScenarios.length > 0">
      <a class="nav-link" [ngClass]="{ active: tab === 'pricingScenarios' }" (click)="selectTab('pricingScenarios')"
        data-bs-toggle="tab" href="#pricingScenarios" role="tab" aria-selected="false"> Pricing Scenarios
        ({{pricingScenarios.length}})</a>
    </li>
  </ul>

  <div class="tab-content">
    <div *ngIf="tab === 'pricingParameters'" class="tab-pane" [ngClass]="{ active: tab === 'pricingParameters'}"
      id="pricingParameters" role="tabpanel">
      <loading-indicator *ngIf="!productSearchRequestInfo" [loadingMessage]="'Loading scenario, please wait...'">
      </loading-indicator>
      <pricing-parameters #pricingParameters *ngIf="productSearchRequestInfo && allEnabledBusinessChannelPerVendor"
        [channelSelectionEnabled]="enableChannelSelection" [businessChannelId]="businessChannelId"
        [enabledChannels]="allEnabledBusinessChannelPerVendor" [customFields]="customFields"
        [transactionType]="transactionType" [applicationChannel]="applicationChannel"
        [productSearchRequest]="productSearchRequestInfo.request" [disableCreditScore]="false"
        [disableDti]="productSearchRequestInfo.disableDTI" [showMultipleColumns]="true"
        [canPriceApplyToLoan]="canPriceApplyToLoan"
        [pricingVendorCredentials]="pricingCredentials" (businessChannelChanged)="onBusinessChannelChanged($event)"
        [visibleFeatureFields]="visibleFeatureFields" (vendorChanged)="onVendorChanged($event)">
      </pricing-parameters>
      <button *ngIf="productSearchRequestInfo" type="button"
        class="btn btn-primary submit-button-style-child max-index pull-right mt-3" id="searchProducts"
        (click)="onSubmitClicked()">
        <i class="fas fa-search me-1"></i> Search Products
      </button>
    </div>
    <div *ngIf="tab === 'pricingResults'" class="tab-pane" [ngClass]="{ active: tab === 'pricingResults'}"
      id="pricingResults" role="tabpanel">
      <product-search-results #searchResults *ngIf="pricingRunResult" [canPriceApplyToLoan]="canPriceApplyToLoan"
        [pricingRunResult]="pricingRunResult" [pricingRunRequest]="pricingRunRequest" [lockStatus]="lockStatus"
        [miIntegrationEnabled]="miIntegrationEnabled"
        [hideQmColumn]="hideQmColumn" [pricedScenarios]="pricedScenarios"
        [existingPinnedPricesByScenario]="existingPinnedPricesByScenario" [requestLockEnabled]="requestLockEnabled"
        [transactionType]="transactionType"
        (ineligibleProductDetailsClicked)="onIneligibleProductDetailsClicked($event)"
        (productDetailsClicked)="onEligibleProductDetailsClicked($event)"
        (qualifiedMortgageDetailsClicked)="onQualifiedMortgageDetailsClicked($event)"
        (productPriceSelectedForApplication)="onProductPriceSelectedForApplication($event)"
        (productPriceRepricedForApplication)="onProductPriceRepricedForApplication($event)"
        (productPriceProgramChangeForApplication)="onProductPriceProgramChangeForApplication($event)"
        (pricePinned)="onPricePinned($event)" (priceUnpinned)="onPriceUnpinned($event)"
        (addToScenariosRequested)="onAddToScenariosRequested($event)"
        (createToScenarioRequested)="onCreateToScenariosRequested($event)">
      </product-search-results>
    </div>
    <div [hidden]="tab !== 'pricingScenarios'" class="tab-pane" id="pricingScenarios" role="tabpanel">
      <pinned-scenarios #pinnedScenarios [ngClass]="{'d-flex': pricingScenarios.length > 0}"
        [scenarios]="pricingScenarios" (refreshAllFinished)="refreshAllFinishedClicked()">
      </pinned-scenarios>
    </div>
  </div>
</div>

<ng-container *ngIf="viewType == 3">
  <lenderprice-pricer [isQuickPricer]="true" *ngIf="vendorSelection == 'LenderPriceIframe'"></lenderprice-pricer>
  <loanpass-pricer [transactionType]="transactionType" *ngIf="vendorSelection == 'LoanPassIframe'" (priceLockClicked)="onPriceLockClicked($event)"></loanpass-pricer>
</ng-container>

<ng-container *ngIf="!viewType">
  <div>
    <div class="d-flex align-items-center justify-content-center"
      [ngStyle]="{'min-height': isTpoUser ? '466px' : '603px'}">
      <div>
        <h4 class="card-title mb-3">Pricing Engine to Use</h4>
        <select [(ngModel)]="vendorSelection" (ngModelChange)="onPricingVendorSelectionChanged()" name="vendorSelection"
          class="form-select">
          <option [ngValue]="null">-- Select One --</option>
          <option *ngIf="showLoanPassIFrame" [ngValue]="'LoanPassIframe'">LoanPass IFrame</option>
          <option *ngIf="showLenderPriceIFrame" [ngValue]="'LenderPriceIframe'">LenderPrice IFrame</option>
          <option [ngValue]="'Others'">All Others</option>
        </select>
      </div>
    </div>
  </div>
</ng-container>
